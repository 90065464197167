$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    // create instance of the plugin, but hold on till needed
    var acc = $('.accHandler').accordion({onhold: true, openFirst: true});
    $win = $(window);

    // start the accordion when window is smaller than 902px
    $win.resize(function() {
        if (breakpoint['medium']) acc.destroy();
        else acc.init();
    });
  
    // trigger resize to check if accordion is needed
    $win.trigger('resize');

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * REPLACE a[href^="tel"] BY SPAN ELEMENT IF NOT MOBILE DEVICE
     */
    if(!('ontouchstart' in window || navigator.maxTouchPoints)) {
        phonelink();
    }

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /*Fixed top*/
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 10) {
            $(".page-navi").addClass("fixed");
        } else {
            $(".page-navi").removeClass("fixed");
        }
    });

    /*Fixed top*/
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 350) {
            $(".spezialgebiete-btn").addClass("fixed");
        } else {
            $(".spezialgebiete-btn").removeClass("fixed");
        }
    });



 $('[data-grab-page]').grabber(
    {
        onCompleteAll:function(e)
        {

                 // Teaser Slider
                $('[data-grab-page]').slick({
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infitite: false
                        }
                    },
                        {
                            breakpoint: 640,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infitite: false
                            }
                        }
                    ]
                });
        }
    });




    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if (breakpoint['medium']) {

    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/